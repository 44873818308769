<template>
  <div>
    <h1 class=" my-3 px-3 p-sm-0 py-0 py-md-3 page-heading w-100">PRIVACY NOTICE (updated May 2024)</h1>
    <div id="terms" class="container bg-white pt-2 px-4 text-left pb-3">
      <p><u><b>ABOUT THIS NOTICE</b></u></p>
      <p>We are committed to protecting the privacy and security of your personal information. This privacy notice describes how we collect and use personal information about you
        in accordance with data protection law in England (which is a jurisdiction we have chosen due to the global nature of our business). Please read it carefully.</p>
      <p>Data protection law says that the personal information we hold about you must be:</p>
      <ul>
        <li>Used lawfully, fairly and in a transparent way.</li>
        <li>Collected only for valid purposes that we have clearly explained to you and not used in any way that is incompatible with those purposes.</li>
        <li>Relevant to the purposes we have told you about and limited only to those purposes.</li>
        <li>Accurate and kept up to date.</li>
        <li>Kept only as long as necessary for the purposes we have told you about.</li>
        <li>Kept securely.</li>
      </ul>
      <p>If you have any questions about this notice or how we collect and use personal information about you please contact us.</p>
      <ol class="mb-5 pb-5">
        <li>
          <b>INFORMATION ABOUT US</b>
          <ol>
            <li>The AnyTask™ Platform involves several companies working together, this privacy notice applies to all of us jointly. We are:
              <ol>
                <li>
                  Belgravia Trading Services Ltd a company registered in England under company number 11150341 with its registered office 51 The Stream Ditton, Aylesford,
                  Kent ME20 6AG (“Belgravia”) and is the owner and licensor of the intellectual property rights in the AnyTask™ Platform jointly to AnyTask and AnyTask-CEP.
                </li>
                <li>
                  AnyTask Ltd a company registered in England under company number 12074221 with its registered office 51 The Stream Ditton, Aylesford, Kent ME20 6AG
                  (“AnyTask”) is a joint licensee and operator of the AnyTask™ Platform. It is also the Buyer’s payment agent.
                </li>
                <li>
                  Genvey 1A N.V. a company registered in the Commercial Register of the Curaçao Chamber of Commerce & Industry under company number 156689 (trading as “ETN-CWP”) is the provider of ETN Wallets and the ETN App.
                </li>
                <li>
                  Genvey 1B N.V. a company registered in the Commercial Register of the Curaçao Chamber of Commerce & Industry under company number 156690 (trading as “AnyTask-CEP”) is a joint licensee and operator of the AnyTask™ Platform. It is also the Seller’s collection agent
                </li>
                <li>
                  Red Cube Marketing Ltd a company registered in England and Wales under company number 11690042 with its registered office 51 The Stream Ditton, Aylesford, Kent ME20 6AG (“RCM”) and is the provider of KYC and AML (Know Your Client and Anti Money Laundering) verification services to the AnyTask™ Platform
                </li>
              </ol>
            </li>
            <li>
              Collectively we act as “joint controllers” of your personal data, but RCM is primarily responsible to you for compliance with data protection law and for responding to any
              queries and requests.
            </li>
            <li>You can contact us via <a href="https://support.anytask.com" target="_blank">https://support.anytask.com.</a></li>
            <li>The AnyTask™ Platform also interoperates with the Electroneum cryptocurrency (“ETN”). For more information on how data may be shared between us and ETN-CWP,
              see the “ETN Information Sharing” section below. ETN-CWP’s privacy notice is available at <a href="https://electroneum.com/privacy-and-cookies-policy/" target="_blank">https://electroneum.com/privacy-and-cookies-policy/.</a>
            </li>
          </ol>
        </li>
        <li>
          <b>ACCOUNT INFORMATION AND OTHER CORRESPONDENCE</b>
          <ol>
            <li><b>If you are an individual, when you hold an account with us</b> there will be personal information about you relating to that
              account or provided through that account such as your name, contact details, profile information, login details, unique identification codes, job details, materials
              and correspondence with us or other AnyTask™ Platform users about your account and services you request or provide. If you wish to minimise the personal data you share on the
              AnyTask™ Platform, you should not include personal data in your job requests or offers on the AnyTask™ Platform.
            </li>
            <li>You will also need to provide certain information to open your account, if you do not, we may not be able to authorise your account. Mandatory information fields
              are generally set out when you are opening an account, but in particular, you must provide your name and unique mobile phone number and/or verified email address. You
              will also need to hold an ETN Wallet in order to use the AnyTask™ Platform as a Seller.
            </li>
            <li><b>Other correspondence or interaction</b> (for example by email, telephone, post, SMS or via the AnyTask™ Platform) between you and us, will include personal information
              (such as names and contact details) in that correspondence. This may include intellectual property infringement notices or claims, enquiries, reviews, follow-up
              comments or complaints lodged by or against you and disputes with you or your organisation.
            </li>
            <li><b>Social media.</b> If we engage with you on social media, we may use information you share with us (including your social media handle or profile) or which is
              available from your account to inform our correspondence with you.
            </li>
            <li><b>Facebook.</b> If you visit our Facebook page, Facebook may be a joint controller with us in relation to information Facebook collects about you regarding your
              visit to interaction with our page or its content. Facebook will provide us with Page Insights, which are aggregated data to help us understand how people are
              engaging with our page. Page Insights do not show us details of individual visitors. For more information on the responsibilities of Facebook and us in relation to
              our Facebook page, please see <a href="https://www.facebook.com/legal/terms/information_about_page_insights_data" target="_blank">https://www.facebook.com/legal/terms/information_about_page_insights_data</a>. For more information about how Facebook uses your
              information, please see <a href="https://en-gb.facebook.com/privacy/explanation" target="_blank">https://en-gb.facebook.com/privacy/explanation</a>.
            </li>
            <li>We will keep and use that information to provide and monitor the AnyTask™ Platform, operate and administer your account (if applicable), to comply with any legal
              requirements for us to maintain certain records or carry out certain verifications and money laundering checks, and/or for our legitimate interests in preventing
              fraud, dealing with a complaint or enquiry and administering your (or your organisation's) account and any services we offer, as well as to review and improve our
              offerings, including troubleshooting, data analysis, testing, research, statistical and survey purposes. We also use some of this information as set out in the
              “Content and Promotional Material” section below.
            </li>
            <li><b>Where your information relates to an account</b>, it is kept for a period of up to seven years after your account is closed to enable us to deal with any after
              sales enquiries or claims and as required for tax purposes.
            </li>
            <li><b>Payment information</b> is collected by our payment card processing provider, Stripe, and used by them in accordance with their privacy notice. Their privacy
              notice is available at <a href="https://stripe.com/gb/privacy" target="_blank">https://stripe.com/gb/privacy</a>.
            </li>
            <li>
              <b>Information regarding cryptocurrency transactions</b> is collected by our cryptocurrency processing facilitator, CoinPayments, and used by them in accordance with their privacy notice. Their privacy notice is available at <a href="https://www.coinpayments.net/help-restricted" target="_blank">https://www.coinpayments.net/help-restricted</a>.
            </li>
            <li>If your information is on social media, it will be retained in accordance with the relevant social media platform’s policies. Any other information set out above
              such as initial enquiries or correspondence which doesn't relate to an account is kept for up to seven years after resolution.
            </li>
          </ol>
        </li>
        <li>
          <b>MARKETING</b>
          <ol>
            <li>We may collect your name and contact details (such as your email address, phone number or address) in order to send you information about our products and services
              which you might be interested in. In order to personalise that information and make it more relevant and helpful for you, we may also use information about how you have
              used the AnyTask™ Platform, or other information you have provided us in relation to your use of the AnyTask™ Platform.
            </li>
            <li><b>You always have the right to "opt out" of receiving our marketing.</b> You can exercise the right at any time by contacting us. If we send you any marketing
              emails, we will always provide an unsubscribe option to allow you to opt out of any further marketing emails. If you "opt-out" of our marketing materials you will be
              added to our suppression list to ensure we do not accidentally send you further marketing. We may still need to contact you for administrative or operational
              purposes, but we will make sure that those communications don't include direct marketing.
            </li>
            <li><b>For individuals.</b> We may use your contact details and other information as necessary for our legitimate interests in marketing to you and maintaining a list of potential customers
              (including, in particular, if we collected them as part of a sale to you or a negotiation with you about a sale). Otherwise (in particular if you are not a customer
              and we haven’t discussed a sale with you) we will only send you marketing emails or texts with your consent.
            </li>
            <li><b>For businesses - If you are a company or LLP (or are acting in a professional capacity as part of one)</b> we use your contact details as necessary for our
              legitimate interests in marketing to you (by various channels) and maintaining a list of potential customers.
            </li>
            <li>As part of our obligations to you we may (at our discretion) promote your willingness to be paid using ETN (by using your Buyer, Seller or Task profiles) to a wider market which
              includes promotion on all social media accounts and websites, in each case for us and our commercial partners (in connection with their services) from time to time as we or they see fit. We never share your name or contact details with third parties for purposes of marketing to you.
              We do use third party service providers to send out our marketing, but we only allow them to use that information on our instructions and where they have agreed to treat the information confidentially and to keep it secure.
            </li>
            <li><b>We retain your details on our marketing list until you "opt-out" at which point we add you to our suppression list.</b> We keep that suppression list
              indefinitely to comply with our legal obligations to ensure we don't accidentally send you any more marketing.
            </li>
            <li>When we send marketing emails to you, we use "web beacons" to collect information about when you open the email, your IP address and browser or email client type,
              and other similar information. We do this as necessary for our legitimate interests in reviewing and improving our direct marketing activities. We may keep this
              information for up to 7 years from the date of collection.
            </li>
          </ol>
        </li>
        <li>
          <b>PLATFORM INFORMATION</b>
          <ol>
            <li><b>We may collect information about you and your use of the AnyTask™ Platform</b> via technical means such as cookies, webpage counters and other analytics and reporting tools. This may include your IP address, login data, information about your transactions, information you submit in forms, browser type and version, time zone setting and location, browser plug-in types and versions, operating system and platform and other technology on the devices you use to access a website, and activity while on our website or elsewhere. We use this as necessary for; (1) our legitimate interests in administering and improving the AnyTask™ Platform and its content (this can include showing real time anonymised information about how people are using the AnyTask™ Platform), to ensure it operates effectively and securely, (2) to develop our business and inform our marketing strategy, (3) administering your account and any subscriptions or competitions we operate, (4) to review and improve our offerings, including troubleshooting, data analysis and reporting, testing, research, statistical and survey purposes.  We may also create aggregate statistical data from that information (for instance, overall numbers of platform visitors) which is not personal information about you. We may supply this information to third party service providers for purposes of improving search and platform recommendation functionality of the AnyTask Platform Where we do so, all user information provided by us to any third party is pseudonymised.</li>
            <li>We, or third party advertisers, may also use this information to serve adverts on you.  Where those adverts are targeted, this may involve using platform information and information we (or our third party advertisers) have obtained from third parties and an automated decision-making process based on information about your interests or status.  This won't include information such as your name or contact details.  Where our adverts are displayed to you using your information, your information is used as necessary for our legitimate interests in marketing to you.</li>
            <li>The information described above about your use of the AnyTask™ Platform may also be collected and used by third party advertisers and analytics providers using cookie technology. You will have the option when using the AnyTask™ Platform to accept or reject those cookies.  If you accept those cookies, your data will be collected and used by those third parties for their own advertising and analytical purposes.  For more information, please see their privacy policies.</li>
            <li><b>Sharing or Like buttons.</b> If you click a “share” or “like” or equivalent social media button on the AnyTask™ Platform, the relevant social media platform may be a joint controller with us in relation to information that is sent to them about you regarding your clicking of that button (which may include the types of information which we collect about you as part of your visit to the AnyTask™ Platform described above). Any information sent to a social media platform is used by them according to their privacy policies, and they will be solely responsible for that use and primarily responsible for providing you with information about any joint processing and enabling you to exercise your rights under data protection law. For more information on the responsibilities of social media platforms and us in relation to social media buttons on the AnyTask™ Platform, please see the privacy notice of the relevant social media platforms.</li>
            <li>For detailed information on the cookies on our site and the purposes which they are used for see our Cookie Notice below.</li>
            <li>We keep this platform information about you for up to 6 months from when it is collected or the relevant cookie expires.</li>
            <li><b>Third party websites.</b> The AnyTask™ Platform may, from time to time, contain links to third party websites, plug-ins and applications. Clicking on those links or enabling those connections may allow third parties to collect or share data about you. We do not control these third party websites and are not responsible for their privacy statements. When you leave the AnyTask™ Platform, we encourage you to read the privacy notice of every website you visit.</li>
          </ol>
        </li>
        <li>
          <b>CONTENT AND PROMOTIONAL MATERIAL</b>
          <ol>
            <li>This is information about you which you or an AnyTask account holder provide to us (whether through the AnyTask™ Platform or otherwise). This may include reviews, comments, testimonials, photographs (including any photos; Buyer, Seller or Task profiles and advertising material), videos, and other content. Please note that any content uploaded to the AnyTask™ Platform is considered non-confidential.</li>
            <li>We may (at our discretion) display and publish this content (and, if relevant, attribute it to you) on any of our platforms, websites or elsewhere as necessary for our legitimate interests in providing content and for promotional purposes (or, in some circumstances, because you have specifically consented to us doing this). In particular we may (at our discretion) use the following details of transactions completed through the AnyTask™ Platform for marketing and promotional purposes:
              <ol>
                <li>Your username</li>
                <li>The title of the Task which was completed</li>
                <li>The description of the Task</li>
                <li>The work (carried out in respect of a Task)</li>
                <li>The name of your company</li>
                <li>Profiles for Sellers, Buyers and Tasks</li>
              </ol>
            </li>
            <li>We may also share this content with <a href="https://electroneum.com/privacy-and-cookies-policy/" target="_blank">the ETN-Network</a> for them to use it in the same way. This information is kept and published or displayed by us for as long as we consider it relevant for those purposes. You can ask us to remove or delete your content at any time (subject to any agreements about our right to use it) by contacting us (although this is not an absolute right). If we are displaying or publishing the information based on your consent, you have the right to withdraw that consent at any time.</li>
          </ol>
        </li>
        <li>
          <b>PROFESSIONAL INFORMATION</b>
          <ol>
            <li>If you work for one of our customers, suppliers or business partners, the information we collect about you may include your contact information, details of your employment and our relationship with you.  This information may be collected directly from you, or provided by your organisation.  Your organisation should have informed you that your information would be provided to us, and directed you to this policy.  We use this as necessary for our legitimate interests in managing our relationship with your organisation.  If we have a business relationship with you or your organisation, we may receive information about you from your organisation.</li>
            <li>We keep this information for up to seven years after the end of our relationship with your organisation.</li>
          </ol>
        </li>
        <li>
          <b>CLAIMS</b>
          <ol>
            <li>
              Where we consider there to be a risk that we may need to defend or bring legal claims, we may retain your personal information as necessary for our legitimate interests in ensuring that we can properly bring or defend legal claims. In particular:
              <ol>
                <li>we may share your email address with a third party who claims that content you upload or post infringes their rights, as well as with relevant law enforcement or regulatory authorities; and</li>
                <li>if you claim that content uploaded or posted to the AnyTask™ Platform infringes your rights, we may share your details and the details of your claim with the person you are claiming against.</li>
              </ol>
            </li>
            <li>We may also need to share this information with our insurers or legal advisers.  How long we keep this information for will depend on the nature of the claim and how long we consider there to be a risk that we will need to defend or bring a claim.</li>
          </ol>
        </li>
        <li>
          <b>ETN INFORMATION SHARING</b>
          <ol>
            <li>
              In order to provide our service and to facilitate payments being made in ETN, we share personal data with and receive it from the ETN-Network as necessary for our and their legitimate interests in providing you with our services and operating and administering the AnyTask™ Platform. In particular:
              <ol>
                <li><b>Accounts.</b> In order use an AnyTask account as a Seller, you must hold an ETN Wallet. We will share your unique mobile number and/or verified email address with the ETN-Network to confirm that you hold a valid ETN Wallet. The ETN-Network will then share with us what due diligence, source of funds and “know your client” checks have been passed by you, although the contents of those checks are not shared.</li>
                <li><b>Payments.</b> When you make or receive a payment through AnyTask, we will share this with the ETN-Network in order to effect the payment, and the ETN-Network will confirm with us that a payment has been successfully made or received.</li>
                <li><b>Fraud and crime prevention.</b> If we or the ETN-Network become aware of any fraud, misconduct or misuse of the AnyTask™ Platform or your ETN Wallet, we will share this information with each other to co-ordinate our response. If you are a Seller and receive ETN following completion of a task on the AnyTask™ Platform the ETN-Network may also share information about that transaction as part of source of funds information requested by third party platforms accepting ETN.</li>
              </ol>
            </li>
            <li><b>Content Sharing.</b> We may (at our discretion) also share content which has been uploaded or posted to the AnyTask™ Platform with the ETN-Network, for them to use, display and publish this content (and, if relevant, attribute it to you) on their platforms, websites or elsewhere as necessary for our and their legitimate interests in providing content and for promotional purposes (or, in some circumstances, because you have specifically consented to us doing this). In particular they may use the following details of transactions completed through the AnyTask™ Platform for marketing and promotional purposes:
              <ol>
                <li>Your username</li>
                <li>The title of the Task which was completed</li>
                <li>The description of the Task</li>
                <li>The work (carried out in respect of the Task)</li>
                <li>The name of your company</li>
                <li>Profiles for Sellers, Buyers and Tasks</li>
              </ol>
            </li>
            <li>This information is kept and published or displayed by them for as long as they consider it relevant for those purposes. You can ask them to remove or delete your content at any time (subject to any agreements you have with them about their right to use it) by contacting them (although this is not an absolute right). If they are displaying or publishing the information based on your consent, you have the right to withdraw that consent at any time.</li>
          </ol>
        </li>
        <li>
          <b>INFORMATION WE RECEIVE FROM THIRD PARTIES</b>
          <ol>
            <li>We may also receive information about you from the following sources:
              <ol>
                <li><b>Our service providers.</b>  We work closely with third parties (including, for example, business partners, sub-contractors in technical, payment and delivery services, advertising networks, analytics providers, search information providers and credit reference agencies) who may provide us with information about you, to be used as set out in this privacy notice.</li>
                <li><b>Businesses we have bought.</b>  If we have acquired another business, or substantially all of its assets, which originally held your information, we will hold and use the information you provided to them, or which they otherwise held about you, in accordance with this privacy notice.  If we are reviewing whether to acquire a business, or substantially all of its assets, which holds your personal data (whether you are a customer or employee of that business or otherwise) we may receive limited personal data about you from that business or professional advisors involved in the transaction, as necessary for our legitimate interests in making decisions about that acquisition.  If we do not acquire that business, any information we receive about you will be deleted as soon as practicable following the decision not to acquire.</li>
              </ol>
            </li>
          </ol>
        </li>
        <li>
          <b>SHARING YOUR INFORMATION</b>
          <ol>
            <li><b>We never sell your data to third parties.</b> But we may need to share your information with third parties, including third-party service providers and other entities in the ETN-Network. Third parties are required to respect the security of your personal information and to treat it in accordance with the law.</li>
            <li><b>Why might we share your personal information with third parties?</b> We may share your personal information with third parties: (1) if we are under a duty to disclose or share your personal information in order to comply with any legal obligations, (2) carry out our contracts with you, or on your behalf for products and/or services with third party providers that you may access via us, (3) or in order to enforce or apply our agreements with you, or to protect the rights, property, or safety of us, our customers, or others or where we have another legitimate interest in doing so.  This may include exchanging information with other companies and organisations for the purposes of fraud protection and credit risk reduction or sharing information with law enforcement or regulatory agencies.</li>
            <li><b>Which third-party service providers process your personal information?</b> We may need to share your personal information with third-party service providers (including contractors and designated agents) so that they can carry out their services. We may use third-party service providers in relation to the following types of activity: legal advice, contract administration, administration, IT services, payment processing.</li>
            <li><b>When might we share your personal information with other entities in the ETN-Network?</b> We may share your personal information with other entities in our corporate network as part of our regular reporting activities on company performance, in the context of a business reorganisation or group restructuring exercise, and for system maintenance support and hosting of data.</li>
            <li><b>How secure is your information with third-party service providers and other entities in the ETN-Network?</b> All our third-party service providers and other entities in the ETN-Network are required to take appropriate security measures to protect your personal information.  Where third parties process your personal information on our behalf as "data processors" they must do so only on our instructions and where they have agreed to treat the information confidentially and to keep it secure.</li>
            <li><b>What about other third parties?</b> We may share your personal information with other third parties, for example with potential buyers and professional advisers in the context of the possible sale or restructuring of the business where necessary in connection with the purposes which your information was collected for.  We may also need to share your personal information with a regulator or to otherwise comply with the law.</li>
          </ol>
        </li>
        <li>
          <b>WHY ELSE DO WE USE YOUR INFORMATION?</b>
          <ol>
            <li><b>Common uses of your information.</b> We will only use your personal information when the law allows us to do so.  Although in limited circumstances we may use your information because you have specifically consented to it, we generally use your information in the ways set out in this notice because:
              <ol>
                <li>we need to perform a contract we have entered into with you.</li>
                <li>we need to comply with a legal obligation.</li>
                <li>it is necessary for our legitimate interests (or those of a third party) and your interests and rights do not override those interests.</li>
                <li>we need to protect your interests (or someone else's interests) or where it is needed in the public interest (although these circumstances are likely to be rare).</li>
              </ol>
            </li>
            <li><b>Change of purpose.</b> We will only use your personal information for the purposes for which we collected it as set out in this notice, unless we reasonably consider that we need to use it for another reason and that reason is compatible with the original purpose.  If we need to use your personal information for an unrelated purpose, we will notify you and we will explain the legal basis which allows us to do so.</li>
          </ol>
        </li>
        <li>
          <b>WHERE WE STORE YOUR INFORMATION</b>
          <ol>
            <li>Our main data centre is located in are with Amazon Web Services <a href="https://aws.amazon.com/blogs/security/all-aws-services-gdpr-ready" target="_blank">https://aws.amazon.com/blogs/security/all-aws-services-gdpr-ready</a> located in Ireland, Japan, Singapore and USA. Where required to perform our contract with you or for our wider business purposes, the information that we hold about you may be transferred to, and stored at, a destination outside EU. It may also be processed by staff operating outside the EU who work for us or for one of our service providers. The AnyTask™ Platform is accessible worldwide, so any information you upload which is not set as private will be viewable from anywhere in the world.</li>
            <li>We will take all steps reasonably necessary to ensure that your personal information is treated securely and in accordance with this privacy notice.</li>
            <li>Some countries or organisations outside of the EU which we may transfer your information to will have an "adequacy decision" in place, meaning the EU considers them to have an adequate data protection regime in place.  These are set out on the European Commission website: <a href="https://ec.europa.eu/info/law/law-topic/data-protection/data-transfers-outside-eu/adequacy-protection-personal-data-non-eu-countries_en" target="_blank">https://ec.europa.eu/info/law/law-topic/data-protection/data-transfers-outside-eu/adequacy-protection-personal-data-non-eu-countries_en</a>.</li>
            <li>If we transfer data to countries or organisations outside of the EU which the EU does not consider to have an adequate data protection regime in place, we will ensure that appropriate safeguards (for example, model clauses and/or standard contractual clauses approved by the EU or a data protection authority) are put in place where required.  To obtain more details of these safeguards, please contact us.</li>
          </ol>
        </li>
        <li>
          <b>DATA SECURITY</b>
          <ol>
            <li>As well as the measures set out above in relation to sharing of your information, we have put in place appropriate internal security measures to prevent your personal information from being accidentally lost, used or accessed in an unauthorised way, altered or disclosed.  In addition, we limit access to your personal information to those employees, agents, contractors and other third parties who have a business need to know.  They will only process your personal information on our instructions and they are subject to a duty of confidentiality.</li>
            <li>We have put in place procedures to deal with any suspected data security breach and will notify you and any applicable regulator of a suspected breach where necessary.</li>
          </ol>
        </li>
        <li>
          <b>HOW LONG WILL WE KEEP YOUR INFORMATION FOR?</b>
          <ol>
            <li>We have set out above indications of how long we generally keep your information.  In some circumstances, it may be necessary to keep your information for longer than that in order to fulfil the purposes we collected it for, including for the purposes of satisfying any legal, accounting, or reporting requirements.</li>
            <li>To determine the appropriate retention period for personal information, we consider the amount, nature, and sensitivity of the personal information, the potential risk of harm from unauthorised use or disclosure of your personal information, the purposes for which we process your personal information and whether we can achieve those purposes through other means, and the applicable legal requirements</li>
            <li>In some circumstances we may anonymise your personal information so that it can no longer be associated with you, in which case we may use such information without further notice to you.</li>
          </ol>
        </li>
        <li>
          <b>YOUR RIGHTS</b>
          <ol>
            <li>Data protection law gives you a number of rights when it comes to personal information we hold about you. The key rights are set out below. Under certain circumstances, by law you have the right to:
              <ol>
                <li><b>Be informed</b> in a clear, transparent and easily understandable way about how we use your personal information and about your rights.  This is why we are providing you with the information in this notice.  If you require any further information about how we use your personal information, please let us know.</li>
                <li><b>Request access</b> to your personal information (commonly known as a "data subject access request").  This enables you to receive a copy of the personal information we hold about you and to check that we are lawfully processing it</li>
                <li><b>Request correction</b> of the personal information that we hold about you.  This enables you to have any incomplete or inaccurate information we hold about you corrected.</li>
                <li><b>Request erasure</b> of your personal information.  This enables you to ask us to delete or remove personal information where there is no good reason for us continuing to process it (for instance, we may need to continue using your personal data to comply with our legal obligations).  You also have the right to ask us to delete or remove your personal information where you have exercised your right to object to processing (see below).</li>
                <li><b>Object to processing</b> of your personal information where we are relying on a legitimate interest (or those of a third party) and there is something about your particular situation which makes you want to object to us using your information on this basis and we do not have a compelling legitimate basis for doing so which overrides your rights, interests and freedoms (for instance, we may need it to defend a legal claim).  You also have the right to object where we are processing your personal information for direct marketing purposes.</li>
                <li><b>Request the restriction of processing</b> of your personal information.  This enables you to ask us to suspend the processing of personal information about you, for example if you want us to establish its accuracy or the reason for processing it.</li>
                <li><b>Request the transfer</b> of your personal information to another party where you provided it to us and we are using it based on your consent, or to carry out a contract with you, and we process it using automated means.</li>
                <li><b>Withdraw consent.</b> In the limited circumstances where we are relying on your consent (as opposed to the other bases set out above) to the collection, processing and transfer of your personal information for a specific purpose, you have the right to withdraw your consent for that specific processing at any time.  Once we have received notification that you have withdrawn your consent, we will no longer process your information for the purpose or purposes you originally agreed to, unless we have another compelling legitimate interest in doing so.</li>
                <li><b>Lodge a complaint.</b> If you think that we are using your information in a way which breaches data protection law, you have the right to lodge a complaint with your national data protection supervisory authority.</li>
              </ol>
              If you want to review, verify, correct or request erasure of your personal information, object to the processing of your personal information, withdraw your consent to the processing of your personal information or request that we transfer a copy of your personal information to another party, please contact us.
            </li>
            <li><b>No fee usually required.</b> You will not have to pay a fee to access your personal information (or to exercise any of the other rights).  However, we may charge a reasonable fee if your request for access is clearly unfounded or excessive.  Alternatively, we may refuse to comply with the request in such circumstances.</li>
            <li><b>What we may need from you.</b> We may need to request specific information from you to help us understand the nature of your request, to confirm your identity and ensure your right to access the information (or to exercise any of your other rights).  This is another appropriate security measure to ensure that personal information is not disclosed to any person who has no right to receive it. If we request any identification from you for this purpose, it is on the basis that it is necessary to comply with our legal obligations, and we will only keep and use this until your identity has been verified.</li>
            <li><b>Timescale.</b> Please consider your request responsibly before submitting it.  We will respond to your request as soon as we can.  Generally, this will be within one month from when we receive your request but, if the request is going to take longer to deal with, we will let you know.</li>
          </ol>
        </li>
        <li>
          <b>CHANGES TO THIS PRIVACY NOTICE</b>
          <br>
          Any changes we make to our privacy notice in the future will be posted on this page and, where appropriate, notified to you by e-mail or otherwise.  Please check back frequently to see any updates or changes to our privacy notice.
        </li>
      </ol>
      <h4 class="text-center mt-5 pt-5"><b>COOKIE NOTICE</b></h4>
      <p><u><b>INFORMATION ABOUT OUR USE OF COOKIES</b></u></p>
      <p>
        Our website uses cookies to distinguish you from other users of our website. This helps us to provide you with a good experience when you browse our website and also allows us to improve our site. By continuing to browse the site, you are agreeing to our use of cookies.
      </p>
      <p>
        A cookie is a small file of letters and numbers that we store on your browser or the hard drive of your computer if you agree. Cookies contain information that is transferred to your computer's hard drive.
      </p>
      <p>
        We use the following cookies:
      </p>
      <ul>
        <li>
          <b>Strictly necessary cookies.</b> These are cookies that are required for the operation of our website. They include, for example, cookies that enable you to log into secure areas of our website, use a shopping cart or make use of e-billing services.
        </li>
        <li>
          <b>Analytical/performance cookies.</b> They allow us to recognise and count the number of visitors and to see how visitors move around our website when they are using it. This helps us to improve the way our website works, for example, by ensuring that users are finding what they are looking for easily.
        </li>
        <li>
          <b>Functionality cookies.</b> These are used to recognise you when you return to our website. This enables us to personalise our content for you, greet you by name and remember your preferences (for example, your choice of language or region).
        </li>
        <li>
          <b>Targeting cookies.</b> These cookies record your visit to our website, the pages you have visited and the links you have followed. We will use this information to make our website and the advertising displayed on it more relevant to your interests. We may also share this information with third parties for this purpose.
        </li>
      </ul>
      <p>
        You can find more information about the individual cookies we use and the purposes for which we use them in the table below:
      </p>
      <table class="table table-striped table-bordered">
        <thead>
        <tr>
          <th>Cookie Name</th>
          <th>Function</th>
          <th>Domain</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td>__zlcmid</td>
          <td>ZenDesk Feedback</td>
          <td>anytask.com</td>
        </tr>
        <tr>
          <td>_ga</td>
          <td>Google Analytics</td>
          <td>anytask.com</td>
        </tr>
        <tr>
          <td>_gat</td>
          <td>Google Analytics</td>
          <td>anytask.com</td>
        </tr>
        <tr>
          <td>_gid</td>
          <td>Google Analytics</td>
          <td>anytask.com</td>
        </tr>
        <tr>
          <td>IDE</td>
          <td>Google Ads</td>
          <td>doubleclick.net</td>
        </tr>
        <tr>
          <td>_uetsid</td>
          <td>Google Tag Manager</td>
          <td>anytask.com</td>
        </tr>
        <tr>
          <td>lang</td>
          <td>LinkedIn Analytics</td>
          <td>linkedin.com</td>
        </tr>
        <tr>
          <td>lidc</td>
          <td>LinkedIn Analytics</td>
          <td>linkedin.com</td>
        </tr>
        <tr>
          <td>bcookie</td>
          <td>LinkedIn Analytics</td>
          <td>linkedin.com</td>
        </tr>
        <tr>
          <td>UserMatchHistory</td>
          <td>LinkedIn Analytics</td>
          <td>linkedin.com</td>
        </tr>
        <tr>
          <td>__ar_v4</td>
          <td>AdRoll</td>
          <td>adroll.com</td>
        </tr>
        <tr>
          <td>__adroll_fpc</td>
          <td>AdRoll</td>
          <td>adroll.com</td>
        </tr>
        <tr>
          <td>__adroll_consent_params</td>
          <td>AdRoll</td>
          <td>adroll.com</td>
        </tr>
        <tr>
          <td>__at_px</td>
          <td>AnyTask</td>
          <td>anytask.com</td>
        </tr>
        <tr>
          <td>__adal_ca</td>
          <td>Adalyser</td>
          <td>anytask.com</td>
        </tr>
        <tr>
          <td>__adal_cw</td>
          <td>Adalyser</td>
          <td>anytask.com</td>
        </tr>
        <tr>
          <td>__adal_id</td>
          <td>Adalyser</td>
          <td>anytask.com</td>
        </tr>
        <tr>
          <td>__adal_ses</td>
          <td>Adalyser</td>
          <td>anytask.com</td>
        </tr>
        </tbody>
      </table>
      <p class="mt-2">Please note that third parties (including, for example, advertising networks and providers of external services like web traffic analysis services) may also use cookies, over which we have no control. These cookies are likely to be analytical/performance cookies or targeting cookies
      </p>
      <p>
        You block cookies by activating the setting on your browser that allows you to refuse the setting of all or some cookies. However, if you use your browser settings to block all cookies (including essential cookies) you may not be able to access all or parts of our site.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PrivacyNotice'
}
</script>

<style scoped>
#terms {
  text-align: left;
  margin-top: 1px;
  margin-bottom: 4rem;
}
#terms h1 {
  text-align: center;
}
#terms ol {
  list-style-type: none;
  counter-reset: item;
  margin: 0;
  padding: 0;
}
#terms ol > li {
  display: table;
  counter-increment: item;
  margin-bottom: 0.6em;
}
#terms ol > li:before {
  content: counters(item, ".") ". ";
  display: table-cell;
  padding-right: 0.6em;
}
#terms li ol > li {
  margin: 0.5rem 0 0.5rem 0;
}
#terms li ol > li:before {
  content: counters(item, ".") " ";
}
ul li {
  list-style: disc;
}
</style>
